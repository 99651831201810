exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-collection-distortion-nature-tsx": () => import("./../../../src/pages/collection/distortion-nature.tsx" /* webpackChunkName: "component---src-pages-collection-distortion-nature-tsx" */),
  "component---src-pages-collection-distortion-reality-tsx": () => import("./../../../src/pages/collection/distortion-reality.tsx" /* webpackChunkName: "component---src-pages-collection-distortion-reality-tsx" */),
  "component---src-pages-collections-tsx": () => import("./../../../src/pages/collections.tsx" /* webpackChunkName: "component---src-pages-collections-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-hm-tsx": () => import("./../../../src/pages/projects/hm.tsx" /* webpackChunkName: "component---src-pages-projects-hm-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */)
}

